
import {Component, Vue, Watch} from "vue-property-decorator";
import PictureUpload from "@/mui/components/PictureUpload";
import {IConfig} from "@/config/types";

/**
 * @name: brand
 * @author: Gzm
 * @date: 2023-07-04 10:33
 * @description：brand
 * @update: 2023-07-04 10:33
 */
import config from "@/config";
import {dictDetailApi, dictSetUpApi} from "@/apis/marketingManage/rules";
// import {adminDictDetailApi, adminDictSetUpApi} from "@/apis/dict";

@Component({
  components: {
    PictureUpload
  }

})
export default class ProductPage extends Vue {
  config: IConfig = config
  logoPic = ""

  //上传前检验
  beforeUpload(file: File): boolean {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 0.5;

    if (!isJPG) {
      this.$message.error('上传图片只能是JPG、JPEG、PNG格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return isJPG && isLt2M;
  }

  onUploadSuccess(res:any,file:any){
    this.logoPic = res.data.filePath
    dictSetUpApi({
      types:5,
      values:res.data.url
    }).then(e => {
      this.$message.success('上传成功')
      this.getData();
    })
  }

  getData(){
    dictDetailApi(5).then(e => {
      console.log(e)
      if (e){
        this.logoPic = e.value
      }
    })
  }

  created() {
    this.getData();
  }
}
